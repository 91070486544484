<template>
  <v-container
    id="products-edit"
    tag="section"
    fluid
  >
    <v-card
      color="transparent"
      flat
    >
      <v-overlay
        :value="loading"
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title v-if="product">
        <v-icon
          class="mr-2"
        >
          mdi-wardrobe-outline
        </v-icon>
        <span>{{ product.title }} - {{ product.store?.name }} - <StateLabel :item="product" /></span>
        <v-btn
          v-if="product.market_place_permalink"
          icon
          color="blue lighten-1"
          target="_blank"
          :href="product.market_place_permalink"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-spacer />
        <v-menu
          bottom
          left
          offset-y
          min-width="300"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :href="'/products/add/?fromId='+product.id"
              class="py-0"
              target="_blank"
            >
              <v-list-item-icon
                class="mr-2"
              >
                <v-icon
                  small
                >
                  mdi-content-duplicate
                </v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>
                {{ $t('forms.buttons.duplicate') }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item
              class="py-0"
              @click="deleteProduct"
            >
              <v-list-item-icon
                class="mr-2"
              >
                <v-icon
                  small
                  color="red darken-2"
                >
                  mdi-delete
                </v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle
                color="red darken-2"
              >
                {{ $t('forms.buttons.delete') }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-tabs>
          <v-tab>{{ $t('views.products.forms.tabs.information') }}</v-tab>
          <v-tab-item
            class="py-2"
            style="background-color: #FAFAFA"
          >
            <v-form ref="form">
              <ProductFeaturesForm
                :form.sync="form"
                :errors.sync="errors"
                update-mode
                show-title
              />
              <v-row>
                <v-col
                  cols="12"
                  md="8"
                  sm="12"
                >
                  <ProductContentForm
                    :title.sync="form.title"
                    :description.sync="form.description"
                    :defects.sync="form.defects"
                    :sizing.sync="form.sizing"
                    :translations.sync="form.translations"
                    :errors.sync="errors"
                    show-title
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="12"
                >
                  <ProductModerationForm
                    :score.sync="form.score"
                    :favorite.sync="form.favorite"
                    :worn-photos.sync="form.wornPhotos"
                    :in-imparfaite-warehouse.sync="form.inImparfaiteWarehouse"
                    show-title
                  />
                </v-col>
              </v-row>
            
              <ProductPhotoForm
                v-model="form.photos"
                :error.sync="errors.photos"
                show-title
              />
            
              <ProductVariantsForm
                v-model="form.variants"
                :category-id="form.categoryId"
                :target="form.target"
                :errors.sync="errors.variants"
                update-mode
                show-title
              />
              <v-card-actions>
                <v-spacer />

                <v-btn
                  color="grey lighten-1"
                  class="white--text"
                  @click="cancel()"
                >
                  <v-icon left>
                    mdi-cancel
                  </v-icon>
                  {{ $t('forms.buttons.cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="save"
                >
                  {{ $t('forms.buttons.save') }}
                </v-btn>
                <v-btn
                  v-if="product.applicable_for_review"
                  color="primary"
                  @click="saveAndModerate"
                >
                  {{ $t('forms.buttons.to_moderate') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-tab-item>

          <v-tab>{{ $t('views.products.forms.tabs.other') }}</v-tab>
          <v-tab-item
            class="py-2"
            style="background-color: #FAFAFA"
          >
            <v-row
              dense
            >
              <v-col
                cols="12"
                lg="6"
                md="6"
                sm="12"
              >
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>Correspondance des Statuts</v-card-title>
                      <v-card-text>
                        <v-simple-table>
                          <tbody>
                            <tr>
                              <td>{{ $t('views.products.forms.labels.status') }}</td>
                              <td><StatusLabel :item="product" /></td>
                            </tr>
                            <tr>
                              <td>{{ $t('views.products.forms.labels.state') }}</td>
                              <td><StateLabel :item="product" /></td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>Associer à un point de vente</v-card-title>
                      <v-card-subtitle>
                        Le fait d'associer un produit à un point de vente, le rend non achetable sur le site & l'appli
                      </v-card-subtitle>
                      <v-card-text>
                        <SalePointSelector v-model="form.salePointId" />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer /><v-btn
                          color="primary"
                          @click="applySalePoint"
                        >
                          {{ $t('forms.buttons.apply') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>Transporteurs disponibles</v-card-title>
                      <v-card-subtitle>
                        Liste des transporteurs disponibles pour l'envoi de ce produit
                      </v-card-subtitle>
                      <v-card-text>
                        <v-chip
                          v-for="carrier in (product.warehouse?.available_carriers || [])"
                          :key="carrier"
                          class="mr-4"
                        >
                          {{ getCarrierName(carrier) }}
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                lg="6"
                md="6"
                sm="12"
              >
                <v-row dense>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        Commission
                      </v-card-title>
                      <v-card-text>
                        <v-simple-table>
                          <tbody>
                            <tr>
                              <td>{{ $t('views.products.forms.labels.commission_model') }}</td>
                              <td>
                                {{ $t('views.products.forms.labels.commission_models.' + product.commission?.model) }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t('views.products.forms.labels.commission_vat_mode') }}</td>
                              <td>{{ product.commission?.vat_mode }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t('views.products.forms.labels.commission_rate') }}</td>
                              <td>{{ product.commission?.rate | rate }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t('views.products.forms.labels.commission_fee') }}</td>
                              <td>{{ product.commission?.fee.amount | currency }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t('views.products.forms.labels.commission_vat_rate') }}</td>
                              <td>{{ product.commission?.vat_rate | rate }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        Historique
                      </v-card-title>
                      <v-card-text>
                        <v-simple-table>
                          <tbody>
                            <tr>
                              <td>{{ $t('views.products.forms.labels.created_at') }}</td>
                              <td>{{ product.created_at | dateFull }}</td>
                            </tr>
                            <tr v-if="product.pending_at">
                              <td>{{ $t('views.products.forms.labels.pending_at') }}</td>
                              <td>{{ product.pending_at | dateFull }}</td>
                            </tr>
                            <tr v-if="product.rejected_at">
                              <td>{{ $t('views.products.forms.labels.rejected_at') }}</td>
                              <td>
                                {{ product.rejected_at | dateFull }}
                              </td>
                            </tr>
                            <tr v-if="product.rejected_at">
                              <td>Raison</td>
                              <td>
                                <RejectionAlert
                                  :item="product"
                                  class="mt-4 mb-4"
                                />
                              </td>
                            </tr>
                            <tr v-if="product.available_for_sale_at">
                              <td>{{ $t('views.products.forms.labels.available_for_sale_at') }}</td>
                              <td>{{ product.available_for_sale_at | dateFull }}</td>
                            </tr>
                            <tr v-if="product.sold_at">
                              <td>{{ $t('views.products.forms.labels.sold_at') }}</td>
                              <td>{{ product.sold_at | dateFull }}</td>
                            </tr>
                            <tr v-if="product.returned_at">
                              <td>{{ $t('views.products.forms.labels.returned_at') }}</td>
                              <td>{{ product.returned_at | dateFull }}</td>
                            </tr>
                            <tr v-if="product.deleted_at">
                              <td>{{ $t('views.products.forms.labels.deleted_at') }}</td>
                              <td>{{ product.deleted_at | dateFull }}</td>
                            </tr>
                            <tr v-if="product.updated_at">
                              <td>{{ $t('views.products.forms.labels.updated_at') }}</td>
                              <td>{{ product.updated_at | dateFull }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
    <Snackbar />
    <ConfirmModal ref="confirm" />
  </v-container>
</template>

<script>
import inputMixin from '@/mixins/input'
import ProductContentForm from '@/components/base/Product/ContentForm'
import ProductFeaturesForm from '@/components/base/Product/FeaturesForm'
import productFormMixin from '@/mixins/productForm'
import ProductModerationForm from '@/components/base/Product/ModerationForm'
import ProductPhotoForm from '@/components/base/Product/PhotoForm'
import ProductVariantsForm from '@/components/base/Product/VariantsForm'
import RejectionAlert from '@/components/core/RejectionAlert.vue'
import SalePointSelector from '@/components/base/Product/Input/SalePointSelector'
import shipment from '@/mixins/shipment'
import Snackbar from '@/components/core/Snackbar'
import snackbarMixin from '@/mixins/snackbar'
import StateLabel from '@/components/core/StateLabel'
import StatusLabel from '@/components/core/StatusLabel'

export default {
  name: 'ProductsEdit',
  components: {
    RejectionAlert,
    ProductModerationForm,
    SalePointSelector,
    ProductFeaturesForm,
    ProductPhotoForm,
    ProductVariantsForm,
    ProductContentForm,
    Snackbar,
    StateLabel,
    StatusLabel,
  },
  mixins: [shipment, snackbarMixin, inputMixin, productFormMixin],
  data() {
    return {
      productId: null,
      mounted: false,
      loading: false,
      loadingText: this.$i18n.t('views.products.forms.messages.load.progress'),
      product: {},
      errors: {
        translations: [],
        variants: [],
      },
      form: {},
    }
  },
  watch: {
    product() {
      this.form = {
        categoryId: this.product.category?.id,
        family: this.product.family,
        brandId: this.product.taxonomies.filter(item => item.kind === 'brand').map(v => v.id)[0],
        conditionId: this.product.taxonomies.filter(item => item.kind === 'state').map(v => v.id)[0],
        colorIds: this.product.taxonomies.filter(item => item.kind === 'color').map(v => v.id),
        materialIds: this.product.taxonomies.filter(item => item.kind === 'material').map(v => v.id),
        lookId: this.product.taxonomies.filter(item => item.kind === 'look').map(v => v.id)[0],
        yearsId: this.product.taxonomies.filter(item => item.kind === 'years').map(v => v.id)[0],
        patternIds: this.product.taxonomies.filter(item => item.kind === 'pattern').map(v => v.id),
        styleIds: this.product.taxonomies.filter(item => item.kind === 'style').map(v => v.id),
        dropIds: this.product.drop_ids,
        salePointId: this.product.sale_point?.id,
        target: this.product.target,
        weight: this.product.weight,
        title: this.product.title,
        description: this.product.description,
        defects: this.product.defects,
        sizing: this.product.sizing,
        translations: this.product.translations ?? [],
        score: this.product.score,
        favorite: this.product.favorite,
        wornPhotos: this.product.worn_photos,
        inImparfaiteWarehouse: this.product.in_imparfaite_warehouse,
        photos: this.product.photos.map((photo) => ({
          id: photo.photo.id,
          isDeleted: photo.deleted,
          photo: {
            src: photo.photo.src,
            kind: photo.photo.kind,
            state: photo.photo.state,
          },
        })),
        variants: this.product.variants.map((variant) => ({
          id: variant.id,
          externalReference: variant.external_reference,
          eanCode: variant.ean_code,
          sizes: variant.sizes.map(size => size.id),
          stock: variant.stock,
          basePrice: variant.base_price,
          price: variant.price,
          discountRate: variant.discount_rate,
          sku: variant.sku,
          measures: variant.measures.map((measure) => ({
            value: measure.value ? parseFloat(measure.value) : null,
            slug: measure.kind,
          })),
        })),
      }

      this.errors = {
        categoryId: null,
        family: null,
        brandId: null,
        conditionId: null,
        colorIds: null,
        dropIds: null,
        materialIds: null,
        lookId: null,
        yearsId: null,
        patternIds: null,
        styleIds: null,
        weight: null,
        title: null,
        description: null,
        defects: null,
        sizing: null,
        translations: this.product.translations.map(() => ({
          title: null,
          description: null,
          defect: null,
          sizing: null,
        })),
        photos: null,
        variants: this.product.variants.map((variant) => ({
          size: null,
          measures: Array(variant.measures.length).fill({ value: null }),
        })),
      }
    },
  },
  async mounted() {
    await this.load(this.$route.params.id)
  },
  methods: {
    async cancel() {
      await this.load(this.product.id)
    },
    async load(id) {
      this.loadingText = this.$i18n.t('views.products.forms.messages.load.progress')
      this.loading = true

      try {
        const response = await this.$axios.get('v3/products/' + id)
        this.product = response.data
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ??
          'No context'
        this.snackbarError(this.$i18n.t('views.curation.forms.messages.load.failed', { error: error_message }))
      } finally {
        this.loading = false
        this.mounted = true
      }
    },
    async saveAndModerate () {
      if (!await this.save()) {
        return
      }

      try {
        await this.$axios.post('v3/products/' + this.product.id + '/reviews', {})
        await this.load(this.product.id)
        this.snackbarSuccess(
          this.$i18n.t('views.products.forms.messages.create_review.succeed', { productTitle: this.product.title })
        )
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ??
          'No context'
        this.snackbarError(this.$i18n.t('views.products.forms.messages.create_review.failed', { error: error_message }))
      } finally {
        this.loading = false
      }
    },

    async save() {
      this.loadingText = this.$i18n.t('views.products.forms.messages.save.progress')
      this.loading = true

      const product = {
        title: this.form.title,
        description: this.form.description,
        target: this.form.target,
        photos: this.form.photos.map((photo) => {
          return {
            id: photo.id,
            kind: photo.photo.kind,
          }
        }),
        category: this.form.categoryId,
        defects: this.form.defects,
        sizing: this.form.sizing,
        translations: this.form.translations.map(v => ({
          language: v.language,
          title: v.title,
          description: v.description,
          defect: v.defect,
          sizing: v.sizing,
        })),
        variants: this.form.variants.map(v => ({
          id: v.id,
          sizes: v.sizes,
          external_reference: v.externalReference,
          ean_code: v.eanCode,
          stock: v.stock,
          base_price: v.basePrice,
          price: v.price,
          discount_rate: v.discountRate,
          measures: v.measures.map(m => ({
            kind: m.slug,
            value: m.value,
          })),
        })),
        family: this.form.family,
        brand: this.form.brandId,
        condition: this.form.conditionId,
        years: this.form.yearsId,
        colors: this.form.colorIds,
        drops: this.form.dropIds,
        materials: this.form.materialIds,
        look: this.form.lookId,
        patterns: this.form.patternIds,
        styles: this.form.styleIds,
        weight: this.form.weight,
        score: this.form.score,
        favorite: this.form.favorite,
        wornPhotos: this.form.wornPhotos,
        inImparfaiteWarehouse: this.form.inImparfaiteWarehouse,
      }

      try {
        const response = await this.$axios.put('v3/products/' + this.product.id, product)
        this.product = response.data
        this.snackbarSuccess(
          this.$t('views.products.forms.messages.save.succeed', { productTitle: this.product.title })
        )

        return true
      } catch (error) {
        if (error?.response?.data['@type'] === 'hydra:Error') {
          this.snackbarError(
            this.$t('views.products.forms.messages.save.failed', {
              error: error?.response?.data['hydra:description'] ?? 'internal',
            })
          )

          return
        }
        if (error?.response?.data['@type'] !== 'ConstraintViolationList') {
          this.snackbarError(this.$t('views.products.forms.messages.save.failed', { error: 'internal' }))

          return
        }

        this.apiViolationToErrors(error?.response?.data?.violations ?? [])
      } finally {
        this.loading = false
      }
    },

    async applySalePoint() {
      if (this.form.salePointId === this.product.salePointId) {
        return
      }
      this.loadingText = this.$i18n.t('views.products.forms.messages.save.progress')
      this.loading = true

      const salePoint = {
        salePoint: this.form.salePointId,
      }

      try {
        const response = await this.$axios.put('v3/products/' + this.product.id + '/attach-to-sale-point', salePoint)
        this.product = response.data
        this.snackbarSuccess(
          this.$t('views.products.forms.messages.apply_sale_point.succeed', { productTitle: this.product.title })
        )

        return true
      } catch (error) {
        if (error?.response?.data['@type'] === 'hydra:Error') {
          this.snackbarError(
            this.$t('views.products.forms.messages.apply_sale_point.failed', {
              error: error?.response?.data['hydra:description'] ?? 'internal',
            })
          )

          return
        }
        if (error?.response?.data['@type'] !== 'ConstraintViolationList') {
          this.snackbarError(this.$t('views.products.forms.messages.apply_sale_point.failed', { error: 'internal' }))

          return
        }

        this.apiViolationToErrors(error?.response?.data?.violations ?? [])
      } finally {
        this.loading = false
      }
    },

    async deleteProduct() {
      this.loading = true
      
      if (await this.$refs.confirm.open(
        'Suppression',
        'Êtes-vous sûr de vouloir supprimer ce produit ?')
      ) {
        try {
          await this.$axios.delete('v3/products/' + this.product.id)
          this.snackbarSuccess(
            this.$i18n.t('views.products.forms.messages.delete.succeed', { productTitle: this.product.title })
          )
        } catch (error) {
          const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            'No context'
          this.snackbarError(this.$i18n.t('views.products.forms.messages.delete.failed', { error: error_message }))
        } finally {
          this.loading = false
        }
      } else {
        
        this.loading = false
      }
      
    },
  },
}
</script>
